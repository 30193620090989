<script>
import "flatpickr/dist/flatpickr.css";
import {APPROVED, REJECTED} from "@/helpers/constant/funding-request-decision";
import {
  DEPOSITCONTRIBUTIONTRANSACTION,
  REGULARISATIONCONTRIBUTIONTRANSACTION,
  REGULARISATIONSUBSCRIPTION
} from "../helpers/constant/modal-type";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import {inject} from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: '+ Utilisateur'
    },
    showDepositContributionButton: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    showDateFilter: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showDecisionButton: {
      type: Boolean,
      default: false
    },
    showAccountTypeInput: {
      type: Boolean,
      default: false
    },
    showRegularisationButton: {
      type: Boolean,
      default: false
    }
  },
  components: {Multiselect},
  setup() {
    const globals = inject("globals");

    return {globals}
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      isSubmitting: false,
      accountType: "ALL",
      accountTypeOptions: [
        { value: 'ALL', label: 'Tous' },
        { value: 'ACTIVE', label: 'Actif' },
        { value: 'INACTIVE', label: 'InActif' },
      ],
      search: ''
    };
  },
  mounted() {
  },
  methods: {
    handleClick(){
      this.$emit('clickButton')
    },
    handleSubmitFilterForm() {
      this.isSubmitting = true;
      this.$emit("submitDateFilter", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.isSubmitting = false;
    },
    handleSearchForm() {
      this.isSubmitting = true;
      const searchBody = {
        query: this.search,
        accountType: this.accountType
      }
      this.$emit("submitSearch", searchBody);
      this.isSubmitting = false;
    },
    handleDecisionButton(decision){
      this.$emit("openFundingRequestDecisionModal", decision)
    },
    openModal(type){
      this.$emit("openModal", type)
    }
  },
  computed: {
    REJECTED() {
      return REJECTED
    },
    APPROVED() {
      return APPROVED
    },
    REGULARISATIONCONTRIBUTIONTRANSACTION() {
      return REGULARISATIONCONTRIBUTIONTRANSACTION
    },
    REGULARISATIONSUBSCRIPTION() {
      return REGULARISATIONSUBSCRIPTION
    },
    showRegularisationButtonModal() {
      return this.showRegularisationButton && this.globals.roles.IS_SUPER_ADMIN
    },
    DEPOSITCONTRIBUTIONTRANSACTION() {
      return DEPOSITCONTRIBUTIONTRANSACTION
    },
  }
};
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">{{ title }}</h4>

        <div class="page-title-right d-flex">
          <ol class="breadcrumb m-0">
            <li v-if="showSearch" class="px-4">
              <form @submit.prevent="handleSearchForm" class="search-form">
                <div class="row justify-content-end">
                  <div class="col-md-5" v-if="showAccountTypeInput">
                    <Multiselect
                        class="form-control"
                        v-model="accountType"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="true"
                        :options="accountTypeOptions"
                    />
                  </div>
                  <div class="col-md-6">
                    <input type="search" class="form-control" placeholder="Rechercher" title="Rechercher" v-model="search" />
                  </div>
                  <div class="col-md-1">
                    <button type="submit" :disabled="isSubmitting" class="btn btn-primary">
                      <i class="la la-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
            <li v-if="showDateFilter" class="px-4">
              <form @submit.prevent="handleSubmitFilterForm">
                <div class="row">
<!--                  <div class="col-xxl-2 col-sm-6">-->
<!--                    <div>-->
<!--                      <flat-pickr placeholder="Select date" v-model="date" :config="config"-->
<!--                                  class="form-control flatpickr-input" id="demo-datepicker"></flat-pickr>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="col-md-5">
                    <input required type="date" class="form-control" id="startDate" title="Date de début" v-model="startDate">
                  </div>
                  <div class="col-md-5">
                    <input required type="date" class="form-control" id="endDate" title="Date de fin" v-model="endDate">
                  </div>
                  <div class="col-md-2 gap-2 justify-content-end">
                    <button type="submit" :disabled="isSubmitting" class="btn btn-primary">
                      <i class="la la-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
            <li class="" v-if="showAddButton" :style="{marginLeft: '35px'}">
              <b-button @click="handleClick" variant="primary" class="btn btn-primary waves-effect waves-light">
                {{ buttonTitle }}
              </b-button>
            </li>
            <li class="d-flex" v-if="showDecisionButton">
              <b-button variant="success" class="btn btn-primary waves-effect waves-light me-2" @click="handleDecisionButton(APPROVED)">Approuver</b-button>
              <b-button variant="danger" class="waves-effect waves-light" @click="handleDecisionButton(REJECTED)">Rejéter</b-button>
            </li>
            <li v-if="showDepositContributionButton">
              <b-button @click="openModal(DEPOSITCONTRIBUTIONTRANSACTION)"
                        variant="success"
                        class="btn btn-primary waves-effect waves-light me-4">
                <i class="bx bx-money align-middle me-2"></i> Effectuer une epargne
              </b-button>
            </li>
          </ol>
          <ul class="breadcrumb m-0" v-if="showRegularisationButtonModal">
            <li>
              <b-button @click="openModal(REGULARISATIONCONTRIBUTIONTRANSACTION)"
                variant="success"
                class="btn btn-primary waves-effect waves-light me-4">
                <i class="bx bx-money align-middle me-2"></i> Regulariser une epargne
              </b-button>
            </li>
            <li>
              <b-button
                  @click="openModal(REGULARISATIONSUBSCRIPTION)"
                  variant="success"
                  class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-shopping-bag align-middle me-2"></i> Regulariser une souscription
              </b-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<style>
  .search-form{
    width: 400px;
  }
</style>